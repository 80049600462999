import { ChangeEvent, DragEventHandler, useCallback, useContext, useRef, useState } from "react";
import classes from "./classes.module.scss";
import { FormContext } from "../FormContext";
import Typography from "components/elements/Typography";
import classNames from "classnames";
import I18n from "components/materials/I18n";

type IProps = {
	name: string;
	keyErrorName?: string;
	label?: string;
	required?: boolean;
	accept: string[];
	description: string;
	existingFileName?: string;
	disabled?: boolean;
	formRef?: React.RefObject<HTMLFormElement>;
	onDrop?: (file: File) => void;
	onFileChange?: (file: File) => void;
};

export default function DragAndDropFile(props: IProps) {
	const inputRef = useRef<HTMLInputElement>(null);
	const [isDragOver, setIsDragOver] = useState(false);
	const [file, setFile] = useState<File | null>(null);
	const context = useContext(FormContext);
	let errors = context?.getMessagesErrors(props.keyErrorName ?? props.name);
	const hasErrors = errors?.length > 0;
	const accept = props.accept.join(",");

	const onClick = useCallback(() => {
		if (!inputRef.current) return;
		inputRef.current.click();
	}, []);

	const handleFileChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		const fileList = event.target.files;
		if (fileList && fileList[0]) {
			setFile(fileList[0]);
			props.onFileChange?.(fileList[0]);
		}
	}, [props]);

	const handleDragOver: DragEventHandler<HTMLDivElement> = (event) => {
		event.preventDefault();
		if (!isDragOver) setIsDragOver(true);
	};

	const handleDragEnter: DragEventHandler<HTMLDivElement> = (event) => {
		event.preventDefault();
		setIsDragOver(true);
	};

	const handleDragLeave: DragEventHandler<HTMLDivElement> = (event) => {
		event.preventDefault();
		setIsDragOver(false);
	};

	const handleDrop: DragEventHandler<HTMLDivElement> = useCallback((event) => {
		event.preventDefault();
		setIsDragOver(false);
		const files = event.dataTransfer.files;
		if (files && files[0]) {
			const form = props.formRef?.current;
			const input = form?.querySelector<HTMLInputElement>(`input[name="${props.name}"]`);

			if (input) input.files = files;

			setFile(files[0]);
			props.onDrop?.(files[0]);
		}
	}, [props]);

	return (
		<div className={classes["root"]} onDragOver={handleDragOver} onDragEnter={handleDragEnter} onDragLeave={handleDragLeave} onDrop={handleDrop}>
			{props.label && (
				<Typography type="span" weight="medium" size="medium">
					{props.label}
				</Typography>
			)}
			<input
				type="file"
				name={props.name}
				accept={accept}
				className={classes["input"]}
				required={props.required}
				onChange={handleFileChange}
				ref={inputRef}
				disabled={props.disabled}
			/>

			<label
				htmlFor={props.name}
				className={classNames(classes["drag-and-drop-container"], {
					[classes["drag-over"]!]: isDragOver,
				})}
				onClick={onClick}>
				<Typography type="p" weight="regular" size="medium">
					{props.description}
				</Typography>
				<Typography type="p" weight="regular" size="medium" className={classes["accept"]}>
					{accept}
				</Typography>
			</label>

			<Typography type="p" weight="regular" size="medium">
				{file?.name ? file?.name : props.existingFileName ? props.existingFileName : I18n.trslt(I18n.asset.component.drag_and_drop_file.no_file_selected)}
			</Typography>

			{hasErrors && (
				<div className={classes["errors-container"]}>
					{errors.map((message, i) => (
						<Typography type="p" size="small" weight="medium" key={i} color="error">
							{message}
						</Typography>
					))}
				</div>
			)}
		</div>
	);
}
