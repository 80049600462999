import MailSuscribeRequestResource from "common/resources/Mail/MailSuscribeRequestResource";
import { singleton } from "tsyringe";
import BaseApi from "../BaseApi";

@singleton()
export default class MailSuscribeApi extends BaseApi {
    private baseurl = `${this.apiUrl}`;

    public async createSuscribtion(mailSuscribeData: MailSuscribeRequestResource) {
        const url = `${this.baseurl}/suscribe-news-letter/`;
        return this.postRequest<{message: string}>(url, { ...mailSuscribeData });
    }
}