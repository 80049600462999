import AuthPageTemplate from "components/PageTemplate/AuthPageTemplate";
import Typography from "components/elements/Typography";
import I18n from "components/materials/I18n";
import classes from "./classes.module.scss";
import { useCallback, useState } from "react";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "components/elements/Button";
import { useNavigate } from "react-router-dom";
import { container } from "tsyringe";
import ModuleConfig from "configs/ModuleConfig";
import Input from "components/materials/Form/InputElement";
import Form from "components/materials/Form";
import { ValidationError } from "common/resources/Resource";
import AuthService from "services/AuthService";
import UserSendPinRequestResource from "common/resources/User/UserSendPinRequestResource";

const modules = container.resolve(ModuleConfig).get().modules;
const authService = container.resolve(AuthService);

export default function ForgotPassword() {
	const navigate = useNavigate();
	const [errors, setErrors] = useState<ValidationError[]>([]);

	const onRedirectClick = useCallback(() => {
		navigate(modules.pages.Login.props.path);
	}, [navigate]);

	const onSubmit = useCallback((event: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
		event.preventDefault();
		setErrors([]);
		const userRequest = UserSendPinRequestResource.hydrate<UserSendPinRequestResource>({
			email: formData["email"] as string,
			lang: I18n.getLang(),
		});

		userRequest
			.validateOrReject()
			.then(() => {
				authService
					.sendPinCode(userRequest)
					.then(() => {
						alert(I18n.trslt(I18n.asset.pages.forgot_password.confirmation));
					})
					.catch((error) => {
						console.error({ error });
						if (error instanceof Array) {
							setErrors(error);
						}
					});
			})
			.catch((error) => {
				console.error({ error });
				if (error instanceof Array) {
					setErrors(error);
				}
			});
	}, []);

	return (
		<AuthPageTemplate tabTitle={I18n.trslt(I18n.asset.pages.forgot_password.page_title)}>
			<div className={classes["root"]}>
				<Typography type="h1" weight="bold">
					{I18n.trslt(I18n.asset.pages.forgot_password.title)}
				</Typography>

				<Form onSubmit={onSubmit} errors={errors} className={classes["form-container"]}>
					<Input
						name="email"
						label={I18n.trslt(I18n.asset.pages.forgot_password.labels.email)}
						additionalInfo={I18n.trslt(I18n.asset.pages.forgot_password.labels.email_additionnal)}
						className={classes["input"]}
						inputClassName={classes["input-content"]}
					/>

					<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY} size={EButtonSize.LARGE} fullwidth type="submit">
						{I18n.trslt(I18n.asset.pages.forgot_password.send_button)}
					</Button>
				</Form>

				<Button variant={EButtonVariant.TEXT} color={EButtonColor.PRIMARY} size={EButtonSize.MEDIUM} onClick={onRedirectClick} className={classes["redirect-button"]}>
					{I18n.trslt(I18n.asset.pages.forgot_password.redirect_link)}
				</Button>
			</div>
		</AuthPageTemplate>
	);
}
