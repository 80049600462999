import { useEffect, useState } from "react";
import { ArchiveBoxArrowDownIcon, ArchiveBoxIcon } from "@heroicons/react/20/solid";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { XMarkIcon } from "@heroicons/react/24/solid";
import DefaultAvatar from "assets/images/default-avatar.svg";
import classNames from "classnames";
import { EAnchorStatus } from "common/enums/Anchor/EAnchorStatus";
import { EProjectStatus } from "common/enums/Project/Status/EProjectStatus";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import ProjectUpdateStatusRequestResource from "common/resources/Project/ProjectUpdateStatusRequestResource";
import Button, { EButtonColor, EButtonVariant } from "components/elements/Button";
import DropDown from "components/elements/DropDown";
import LazyImage from "components/elements/LazyImage";
import Typography from "components/elements/Typography";
import DownloadProofButton from "components/materials/DownloadProofButton";
import I18n from "components/materials/I18n";
import I18nStore from "components/materials/I18n/I18nStore";
import PosterImage from "components/materials/PosterImage";
import StatusIcon from "components/materials/StatusIcon";
import { format } from "date-fns";
import AdminFileService from "services/admin/FileService";
import AdminProjectService from "services/admin/ProjectService";
import ProjectService from "services/ProjectService";
import { match } from "ts-pattern";
import { container } from "tsyringe";
import classes from "./classes.module.scss";
import Social from "components/materials/SocialButton";

type IProps = {
	isOpen: boolean;
	onClose: () => void;
	onChangeGeneratedPosterImage: () => void;
	canChangeGeneratedPosterImage: boolean;
	project: ProjectResponseResource;
	onProjectChange: () => void;
	toogleModal: (project: ProjectResponseResource) => void;
};

const adminFileService = container.resolve(AdminFileService);
const adminProjectService = container.resolve(AdminProjectService);

function formatDate(date: Date): string {
	return format(date, "yyyy-MM-dd");
}

export default function Drawer(props: IProps) {
	const [isSmallScreen, setIsSmallScreen] = useState(window.outerWidth <= 1440); // Assuming $screen-lg is 1024px

	const getPosterUrl = async (project: ProjectResponseResource) => {
		return project.poster?.file ? adminFileService.getFileUrl("POSTER", project.poster.file.id) : null;
	};
	const getAvatar = () => {
		const folder = "AVATAR";
		const fileId = props.project?.author?.avatar?.id;
		if (!fileId) {
			console.error("No avatar ID found");
			return null;
		}
		return adminFileService.getFileUrl(folder, fileId);
	};

	const publicationDate = () => {
		const createdAt = props.project?.createdAt;
		if (!createdAt) return "";

		const locale = match(I18nStore.getInstance().lang)
			.with("fr", () => "fr-FR")
			.with("en", () => "en-US")
			.exhaustive();

		return new Date(formatDate(createdAt)).toLocaleDateString(locale, { day: "numeric", month: "long", year: "numeric" });
	};

	const getPitch = () => {
		return match(I18nStore.getInstance().lang)
			.with("fr", () => props.project?.pitch)
			.with("en", () => props.project?.pitch_en)
			.exhaustive();
	};

	const downloadScenario = () => {
		const folder = "SCENARIO";
		const fileId = props.project?.scenario?.file?.id;
		if (!fileId) {
			console.error("No file id found for scenario");
			return;
		}
		const url = adminFileService.getFileUrl(folder, fileId);
		window.open(url, "_blank");
	};

	const downloadCv = () => {
		const folder = "CV";
		const fileId = props.project?.cv?.id;
		if (!fileId) {
			console.error("No file id found for cv");
			return;
		}
		const url = adminFileService.getFileUrl(folder, fileId);
		window.open(url, "_blank");
	};

	const handleStatusChange = async (index: number) => {
		if (!props.project) return;
		const status = () => {
			switch (index) {
				case 0:
					return EProjectStatus.STUDYING;
				case 1:
					return EProjectStatus.ACCEPTED;
				case 2:
					return EProjectStatus.REFUSED;
				default:
					return EProjectStatus.STUDYING;
			}
		};

		const request = ProjectUpdateStatusRequestResource.hydrate<ProjectUpdateStatusRequestResource>({
			status: status(),
		});

		await adminProjectService.updateStatus(props.project.id, request);
		props.onProjectChange();
	};

	const archiveProject = async () => {
		await adminProjectService.archive(props.project!.id);
		props.onProjectChange();
	};

	const restoreProject = async () => {
		await adminProjectService.restore(props.project!.id);
		props.onProjectChange();
	};

	const hasSocials = () => {
		return props.project?.x || props.project?.discord || props.project?.instagram || props.project?.linkedin;
	};

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth <= 1440);
		};
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div className={classes["root"]}>
			<div
				className={classNames(classes["drawer"], {
					[classes["open"]!]: props.isOpen,
				})}>
				<div className={classes["close-container"]}>
					<XMarkIcon className={classes["close-icon"]} onClick={props.onClose} />
				</div>

				<div className={classes["header"]}>
					<div className={classes["poster-container"]}>
						{props.isOpen && <PosterImage project={props.project} getPosterUrl={getPosterUrl} />}
						{props.canChangeGeneratedPosterImage && (
							<Button variant={EButtonVariant.TEXT} color={EButtonColor.PRIMARY} onClick={props.onChangeGeneratedPosterImage}>
								<ArrowPathIcon />
								<Typography type="p" size="large" weight="medium">
									{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.poster.regenerate)}
								</Typography>
							</Button>
						)}
					</div>

					<div className={classes["info-container"]}>
						<div className={classes["author-container"]}>
							<Typography type="p" size="medium" weight="bold">
								{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.author)}
							</Typography>

							<div className={classes["author"]}>
								{props.isOpen && <LazyImage src={getAvatar() ?? DefaultAvatar} alt="Poster" className={classes["avatar"]} />}
								<div className={classes["user"]}>
									<Typography type="p" size="small" weight="bold">
										{props.project?.author.firstName} {props.project?.author.lastName}
									</Typography>
									<Typography type="p" size="small" weight="bold">
										<a href={`mailto:${props.project?.author.email}`} className={classes["email-link"]}>
											{props.project?.author.email}
										</a>
									</Typography>
									<Typography type="p" size="small" weight="bold">
										{props.project?.author.phoneNumber}
									</Typography>
								</div>
							</div>
						</div>

						<div className={classes["info"]}>
							<Typography type="p" size="medium" weight="bold">
								{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.infos.title)}
							</Typography>

							<Typography type="p" size="small" weight="regular">
								{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.infos.publication_date)} {publicationDate()}
							</Typography>

							<Typography type="p" size="small" weight="regular">
								{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.infos.genre)}{" "}
								{props.project && I18n.trslt(I18n.asset.enums.EProjectThemes[props.project.theme])}
							</Typography>

							<Typography type="p" size="small" weight="regular">
								{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.infos.format)}{" "}
								{props.project && I18n.trslt(I18n.asset.enums.EProjectFormats[props.project.format])}
							</Typography>

							{props.project && (
								<Typography type="p" size="small" weight="regular">
									{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.infos.reason)}{" "}
									{props.project.purpose.length > 0
										? props.project.purpose.map(({ purpose }) => I18n.trslt(I18n.asset.enums.EProjectPurposes[purpose])).join(" — ")
										: I18n.trslt(I18n.asset.enums.EProjectReasons[props.project.reason])}
								</Typography>
							)}

							<div className={classes["status"]}>
								{props.project && ProjectService.isPurposeOnlyAnchor(props.project) ? (
									<StatusIcon status={props.project?.scenario?.anchor?.status || EAnchorStatus.QUEUED} withDescription />
								) : (
									<DropDown
										options={[
											<StatusIcon status={EProjectStatus.STUDYING} withDescription />,
											<StatusIcon status={EProjectStatus.ACCEPTED} withDescription />,
											<StatusIcon status={EProjectStatus.REFUSED} withDescription />,
										]}
										onOptionClick={handleStatusChange}
										menuClassName={classes["menu"]}>
										<div>
											<StatusIcon status={props.project?.status || EProjectStatus.STUDYING} withDescription />
										</div>
									</DropDown>
								)}
								{props.project && ProjectService.isAccepted(props.project) && (
									<Typography type="p" size="xsmall" weight="medium" className={classes["status-description"]}>
										{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.infos.accepted_nota_bene)}
									</Typography>
								)}
							</div>
						</div>
					</div>
				</div>

				<div className={classes["body"]}>
					<Typography type="h1" weight="bold">
						{props.project?.title}
					</Typography>
					<Typography type="p" size="medium" weight="regular">
						{getPitch()}
					</Typography>
				</div>
				<div className={classes["button-container"]}>
					<div className={classes["download"]}>
						<div className={classes["social-title"]}>
							{hasSocials() && props.project && <Typography>{I18n.trslt(I18n.asset.pages.project.follow_us)}</Typography>}
							<div className={classes["social-download"]}>{hasSocials() && props.project && <Social project={props.project} />}</div>
						</div>
					</div>

					<div className={classes["anchor"]}>
						<div className={classes["archive"]}>
							{props.project && props.project.archivedAt ? (
								<Button variant={EButtonVariant.SELECTOR} isSelected onClick={restoreProject}>
									<ArchiveBoxIcon />
									<Typography type="span" size="medium" weight="regular">
										{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.buttons.restore)}
									</Typography>
								</Button>
							) : (
								<Button variant={EButtonVariant.SELECTOR} isSelected onClick={archiveProject}>
									<ArchiveBoxArrowDownIcon />
									<Typography type="span" size="medium" weight="regular">
										{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.buttons.archive)}
									</Typography>
								</Button>
							)}
						</div>
						{!props.project?.scenario?.anchor ? (
							<Button color={EButtonColor.PRIMARY} variant={EButtonVariant.CONTAINED} onClick={() => props.toogleModal(props.project)} fullwidth>
								<Typography type="span" size="medium" weight="regular">
									{I18n.trslt(I18n.asset.pages.my_projects.drawer.button.anchor)}
								</Typography>
							</Button>
						) : (
							props.project?.scenario?.anchor?.status === EAnchorStatus.VERIFIED_ON_CHAIN && (
								<DownloadProofButton project={props.project} isSmallScreen={isSmallScreen} />
							)
						)}
						<>
							{props.project?.cv && (
								<Button variant={EButtonVariant.OUTLINED} color={EButtonColor.PRIMARY} onClick={downloadCv} className={classes["button"]}>
									<Typography className={classes["button-text"]}>{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.buttons.cv)}</Typography>
								</Button>
							)}
							{props.project?.scenario?.file?.name && (
								<Button variant={EButtonVariant.OUTLINED} color={EButtonColor.PRIMARY} onClick={downloadScenario} className={classes["button"]}>
									<Typography className={classes["button-text"]}>{I18n.trslt(I18n.asset.pages.admin_dashboard.drawer.buttons.scenario)}</Typography>
								</Button>
							)}
						</>
					</div>
				</div>
			</div>

			{props.isOpen && <div className={classes["overlay"]} onClick={props.onClose} />}
		</div>
	);
}
