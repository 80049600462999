import { useCallback, useEffect, useState } from "react";
import classes from "./classes.module.scss";
import { ValidationError } from "common/resources/Resource";
import Form from "components/materials/Form";
import Input from "components/materials/Form/InputElement";
import I18n from "components/materials/I18n";
import MailSubscribeRequestResource from "common/resources/Mail/MailSuscribeRequestResource";
import { container } from "tsyringe";
import MailSuscribingService from "services/MailSuscribingService";
import Button, { EButtonColor, EButtonVariant } from "components/elements/Button";
import Typography from "components/elements/Typography";

const suscribeService = container.resolve(MailSuscribingService);

type IProps = {
	variant?: "primary" | "secondary";
};

export default function SuscribeNewsLetterButton(props: IProps) {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState<ValidationError[]>([]);
	const [hasSuscribed, setHasSuscribed] = useState<boolean>(false);
	const [isSmallScreen, setIsSmallScreen] = useState(window.outerWidth <= 480); // Assuming $screen-xs is 480px

	const onSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			event.preventDefault();
			setIsLoading(true);
			setErrors([]);
			const suscribeRequestResource = MailSubscribeRequestResource.hydrate<MailSubscribeRequestResource>({
				email: formData["email"] as string,
			});
			suscribeRequestResource
				.validateOrReject()
				.then(() => {
					suscribeService
						.createSuscribtion(suscribeRequestResource)
						.then(() => {
							setIsLoading(false);
						})
						.catch((error: ValidationError) => {
							setIsLoading(false);
							console.error({ error });
							if (error instanceof Array) {
								setErrors(error);
							}
						});
					setHasSuscribed(true);
				})
				.catch((error) => {
					setIsLoading(false);
					console.error({ error });
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[setErrors],
	);

	useEffect(() => {
		const handleResize = () => {
			setIsSmallScreen(window.innerWidth <= 480);
		};
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<div className={props.variant ? classes[props.variant] : classes["primary"]}>
			{hasSuscribed ? (
				<div className={classes["modal"]}>
					<Typography type="p" size="medium" weight="regular">
						{I18n.trslt(I18n.asset.component.suscribe.suscribed_newsletter)}
					</Typography>
				</div>
			) : (
				<Form onSubmit={onSubmit} errors={errors} className={classes["root"]}>
					<div className={classes["input-container"]}>
						<Input name="email" placeholder="Email" inputClassName={props.variant ? classes[`input-${props.variant}`] : classes["input-primary"]} />
					</div>
					<div className={classes["button-container"]}>
						<Button
							type="submit"
							variant={EButtonVariant.CONTAINED}
							color={props.variant && props.variant === "secondary" ? EButtonColor.PRIMARY : EButtonColor.NEUTRAL}
							isLoading={isLoading}
							fullwidth={isSmallScreen}>
							{I18n.trslt(I18n.asset.component.suscribe.suscribe_newsletter)}
						</Button>
					</div>
				</Form>
			)}
		</div>
	);
}
