import MailSuscribeApi from "api/clapaction/MailSuscribe";
import { container, singleton } from "tsyringe";

@singleton()
export default class MailSuscribingService {
	public api = container.resolve(MailSuscribeApi);

	public async createSuscribtion(...args: Parameters<MailSuscribeApi["createSuscribtion"]>) {
		return this.api.createSuscribtion(...args);
	}
}
