import { EProjectFormat } from "common/enums/ProjectFormat/ProjectFormat";
import { EProjectPurpose } from "common/enums/ProjectPurpose";
import { EProjectReason } from "common/enums/ProjectReason";
import { EProjectTheme } from "common/enums/ProjectTheme/ProjectTheme";
import { EValidationError } from "common/enums/Validation/ValidationErrors";

const EProjectReasonImplementation: Record<EProjectReason, string> = {
	PROTECT: "I am submitting my project simply to protect it",
	PROTECT_AND_DEVELOPMENT_AID: "I am submitting my project to protect it and submit it for development assistance",
};

const EProjectPurposeImplementation: Record<EProjectPurpose, string> = {
	FUNDING_APPLICATION: "Submit it to the LaDCF (La Diversité du Cinéma Français) selection committee in order to qualify for development aid",
	AUTHENTICITY_PROTECTION: "To protect it and obtain a certificate of authenticity",
	PITCH_TO_PROFESSIONALS: "Get a chance to pitch it to professionals thanks to Neeedco",
};

const EProjectFormatImplementation: Record<EProjectFormat, string> = {
	court_metrage: "Short Screen",
	documentaire: "Documentary",
	long_metrage: "Feature Film",
	serie: "Series",
};

const EProjectThemeImplementation: Record<EProjectTheme, string> = {
	comedie: "Comedy",
	comedie_romantique: "Romantic Comedy",
	comedie_dramatique: "Dramatic Comedy",
	biopic: "Biopic",
	thriller: "Thriller",
	film_daction: "Action Film",
	film_policier: "Crime Film",
	film_dhorreur: "Horror Film",
	film_historique: "Historical Film",
	science_fiction: "Science Fiction",
	film_danimation: "Animated Film",
	comedie_musicale: "Musical Comedy",
	dramedie: "Dramedy",
	comedie_absurde: "Absurd Comedy",
	thriller_psychologique: "Psychological Thriller",
};

const EValidationErrorImplementation: Record<EValidationError, string> = {
	isArray: "You must select at least one value.",
	isBoolean: "This field is required.",
	isEnum: "This field is required.",
	isInt: "Make sure to enter a whole number, without decimals.",
	isNotEmpty: "This field is required.",
	isNumber: "This field is required. The value must be a number.",
	isObject: "This field is required.",
	isString: "This field is required.",
	isUrl: "Please enter a valid URL.",
	max: "The number must be less than or equal to {{var1}}.",
	min: "The number must be greater than or equal to {{var1}}.",
	badCredentials: "Incorrect credentials. Please try again.",
	emailNotExists: "The email does not exist.",
	isEmail: "Please enter a valid email address.",
	isNumberString: "This field is required. The value must be numeric.",
	isPositive: "The number must be positive.",
	maxLength: "The text length must not exceed {{var1}} characters.",
	passwordNotMatch: "The passwords do not match.",
	isStrongPassword: "The password must be more complex for security reasons.",
	pinCodeNotValid: "The PIN code is not correct.",
	uniqueEmail: "Please use a different email address, this one is already in use.",
	wrongPassword: "The password you entered is incorrect.",
	arrayNotEmpty: "This field is required.",
	match: "The values do not match.",
	minLength: "The text length must be at least {{var1}} characters.",
	emailNotFound: "The email address you provided is not associated with any account.",
	fileNotExists: "The file does not exist.",
	emailCaseSensitive: "The email address must be in lowercase.",
	anchorNotExists: "The anchor does not exist.",
	choiceNotExists: "The choice does not exist.",
	mustBeInThePast: "The date must be in the past.",
	uniqueSha256: "It is not possible to submit the same file twice.",
	invalidPhoneNumber: "Only french or international phone numbers are accepted.",
	invalidLinkedInUrl: "Please enter a valid LinkedIn URL.",
	isDate: "Please enter a valid date.",
};

const trads = {
	common: {
		cancel: "Cancel",
		logout: "Logout",
		search: "Search",
		yes: "Yes",
		no: "No",
		save: "Save",
		back: "Back",
		delete: "Delete",
		add: "Add",
		next: "Next",
		crew: "The team of the film",
	},
	pages: {
		admin_dashboard: {
			page_title: "Submitted projects",
			title: "Submitted projects",
			filters: {
				all_projects: "All projects",
				accepted: "Retained",
				refused: "Not retained",
				studying: "Studying",
				archived: "Archived",
			},
			table: {
				need_to_be_studied: {
					yes: "Yes",
					no: "No",
				},
				headers: {
					project_name: "Project name",
					author: "Author",
					status: "Status",
					date: "Date",
				},
				buttons: {
					cv: "Download CV",
					scenario: "Download scenario",
					data: "Project data",
					status: "Status",
					archive: "Archive",
					restore: "Restore",
					anchor: "Anchor",
				},
				no_cv: "No CV",
				no_scenario: "No scenario",
			},
			drawer: {
				poster: {
					regenerate: "Regenerate a poster",
					pending: "Image generation in progress",
					failed: "The image could not be generated by AI. This may be due to a link in your pitch or the use of potentially inappropriate words.",
				},
				author: "Author",
				infos: {
					title: "Informations",
					publication_date: "Publication:",
					genre: "Genre:",
					format: "Format:",
					reason: "Reason:",
					accepted_nota_bene: "NB: Your project has been selected to be submitted to the LaDCF Selection Committee.",
				},
				buttons: {
					cv: "Download CV",
					scenario: "Download scenario",
					archive: "Archive",
					restore: "Restore",
				},
			},
		},
		project: {
			page_title: "Project page",
			publication: "Publication: ",
			genre: "Genre: ",
			format: "Format: ",
			buttons: {
				readMore: "Read more",
				readLess: "Read less",
			},
			follow_us: "Follow us on :",
		},
		featuredProjects: {
			grand_saut: {
				page_title: "Le grand saut",
				header: {
					original_idea: "Based on an original idea by:",
					writer: "Written by:",
					properties: {
						publication: {
							key: "Publication:",
							value: "May 10, 2024",
						},
						genre: {
							key: "Genre:",
							value: "Romantic Comedy",
						},
						format: {
							key: "Format:",
							value: "Feature Film",
						},
						referent: {
							key: "Referent Agent:",
							value: "Isabelle Frère - Company: If Only",
						}
					},
					box: "Beginning of writing the first version",
				},
				content: {
					title: "Le grand saut",
					subtitle: "Until death do them part",
					description:
						"Le Grand Saut tells the story of Antoine and Lucie, two broken souls with nothing in common except one strange shared desire: to end it all. Their chance meeting in an ambulance, after each survives a failed suicide attempt, sets their lives—or deaths—on an unexpected trajectory. Through midnight escapades, explosive confrontations, and sharp, darkly ironic dialogues, their shared journey becomes a surreal escape. As their misadventures unfold, they begin to open up to one another, confronting their deepest wounds. In their desperate quest, this dysfunctional duo reveals the resilience of the survival instinct and the strange power of human connection, even in the darkest moments.",
				},
				tab: {
					actions: "Actions",
					forum: "Forum",
					media: "Media",
				},
				vote: {
					title: "Vote in progress",
					description: "Have you ever experienced such intense despair that it led you to consider ending your life?",
					options: ["Yes", "No"],
				},
				casting: {
					title: "Become a Voice in the Casting",
					description:
						"In our forum space, the casting director and the director invite you to contribute by suggesting your actor choices for the roles described in their announcements. It’s your turn!",
					button: "View the Forum",
				},
				action: {
					title: "Upcoming Action",
					description:
						"We will select 3 members from the ClapAction community to read the outline for The Big Leap and give us their feedback! Reminder: an outline is a document that presents a film’s script in a simplified and less developed form. If you would like to participate in the development of The Big Leap by providing us with your reader feedback, apply by sending your resume and LinkedIn profile to: contact@clapaction.com. Results will be announced on December 15.",
				},
				forum: {
					title: "Project Forum",
					anwsers: "{{n}} anwsers",
					single_anwser: "{{n}} answer",
				},
			},
			malgre_moi: {
				page_title: "Malgré moi",
				header: {
					author: "Laurent Bouhnik",
					properties: {
						publication: {
							key: "Publication:",
							value: "May 10, 2024",
						},
						genre: {
							key: "Genre:",
							value: "Psychological Thriller",
						},
						format: {
							key: "Format:",
							value: "Feature Film",
						},
						referent: {
							key: "Referent Agent:",
							value: "Ghislaine Gracieux",
						}
					},
					box: "Final version reading - Start of casting",
					adaptation: "Adaptation of the novel 'Despite Myself' by Claire Norton",
				},
				content: {
					title: "Malgré moi",
					subtitle: "The Power of Denial",
					description:
						"Théo, a man scarred by a fire that shattered his childhood, finds his world falling apart when his wife vanishes without explanation. Caught between therapy sessions with a psychiatrist and haunting flashbacks, he struggles to uncover what led to her sudden abandonment.",
				},
				tab: {
					actions: "Actions",
					forum: "Forum",
				},
				vote: {
					title: "Vote in progress",
					description: "Do you regularly consult a psychologist/psychiatrist?",
					options: ["Yes", "No"],
				},
				action: {
					title: "Upcoming action",
					description:
						"Soon, two of you will have the privilege of reading the final version of the script. Then, the community will express its preferences for the actor they would like to see play the role of a psychiatrist over 60 years old.",
				},
				forum: {
					title: "Project Forum",
					anwsers: "{{n}} anwsers",
					single_anwser: "{{n}} answer",
				},
			},
			kdanse: {
				page_title: "KDanse",
				header: {
					author: "Philippe Gray",
					properties: {
						publication: {
							key: "Publication:",
							value: "May 10, 2024",
						},
						genre: {
							key: "Genre:",
							value: "Dramedy",
						},
						format: {
							key: "Format:",
							value: "Feature Film",
						},
						referent: {
							key: "Referent Agent:",
							value: "Fred Malek / Agency Caractère",
						}
					},
					box: "Looking for a co-writer. Ideally, we are also looking for a director.",
				},
				content: {
					title: "En K-Danse",
					subtitle: "The Fields of Resilience",
					description:
						"In the serene mountains of Haute-Savoie, a family struggles with illness, debt, and the weight of unspoken truths. David, the younger son and a devoted farmer, has his world turned upside down by the unexpected return of Émilie, his sister, who has become Emile—a transgender K-Pop enthusiast. With an idea as bold as it is absurd, Emile suggests entering a K-Pop competition to save the family farm. Amid clashes and reconciliations, this unexpected journey reveals their wounds and their resilience. But time is not on their side: will they manage to succeed in the competition—and overcome their own inner conflicts?",
				},
				tab: {
					actions: "Actions",
					forum: "Forum",
				},
				vote: {
					title: "Vote in progress",
					description: "Are you familiar with the world of K-POP?",
					options: ["Yes", "No"],
				},
				action: {
					title: "Upcoming action",
					description: "Soon, you will be able to help us by suggesting ideas and voting to find the ideal director for this film.",
					contact: "Contact us",
				},
				forum: {
					title: "Project Forum",
					anwsers: "{{n}} anwsers",
					single_anwser: "{{n}} answer",
				},
			},
			friends: {
				page_title: "Celle qui n'avait pas vu Friends",
				header: {
					author: "Charlotte Gabris",
					properties: {
						publication: {
							key: "Publication:",
							value: "May 10, 2024",
						},
						genre: {
							key: "Genre:",
							value: "Absurd Comedy",
						},
						format: {
							key: "Format:",
							value: "Feature Film",
						},
						referent: {
							key: "Referent Agent:",
							value: "Alexandra Schamis / AS TALENTS",
						}
					},
					box: "Film in financing",
				},
				content: {
					title: "Celle qui n'avait pas vu Friends",
					subtitle: "When you get dumped on your wedding day...",
					descriptionPart1:
						"Greg, 34, meets his soon-to-be wife, Rachel Karlson, 30, just minutes before the ceremony. She looks stunning... He feels like exclaiming, 'Oh. My. God!!!'—a nod to the iconic",
					descriptionPart2: "Friends",
					descriptionPart3:
						"line. But Rachel doesn’t get the joke. Why? She’s never really watched the show—she’s never even liked it. Greg is stunned. Suddenly, he’s questioning everything. He’s not sure he can go through with the wedding.",
				},
				tab: {
					actions: "Actions",
					forum: "Forum",
					casting: "Casting",
				},
				vote: {
					title: "Vote in progress",
					description: "Have you ever watched the series Friends?",
					options: ["Yes", "No"],
				},
				action: {
					title: "Upcoming action",
					description: "Apply for one of the remaining lead roles in the film by sending us your auditions. We will share the details on how to participate soon.",
					voteTitle: "Vote past",
					options: ["Yes", "No"],
				},
				forum: {
					title: "Project Forum",
					anwsers: "{{n}} anwsers",
					single_anwser: "{{n}} answer",
				},
				casting: {
					title: "Casting confirmed",
					male: "Played by ",
					female: "Played by ",
				},
			},
			lattente: {
				page_title: "L’attente",
				header: {
					novel_basis: "Based on the first novel by Sébastien Praicheux",
					screenplay_adaptation: "Adapted and written by ",
					author: "Sébastien Praicheux",
					properties: {
						publication: {
							key: "Publication:",
							value: "December 10, 2024",
						},
						genre: {
							key: "Genre:",
							value: "Dramatic Comedy",
						},
						format: {
							key: "Format:",
							value: "Feature Film",
						}
					},
					box: "In development",
				},
				content: {
					title: "L’attente",
					description:
						"In a world where everything moves too fast, Serge Lassart, a former worker turned visionary, imagines the unthinkable: a vast universal waiting room, a place where everyone is invited to take a break, slow down, and reflect on the meaning of their life.\nFrom this space emerges a collective movement, disrupting habits and revealing the intertwined destinies of characters as different as they are endearing.\nLives on hold, intertwined destinies: the narrator, a melancholic man in search of meaning; the twin sisters, mysterious travelers in search of their origins; Sandrine, a childhood love; and April, a cynical figure of modern society, trapped in a world of performance and appearances.\nWith a choral staging, The Waiting Room questions our relationship with time, solitude, and the necessity of reconnecting with the essential.\nAs the trajectories of these characters intertwine, each wait reveals an essential truth. A heart-wrenching tragedy where fate reshuffles the cards, questioning our need for recognition and the true meaning of happiness.",
				},
				tab: {
					actions: "Actions",
					forum: "Forum",
				},
				vote: {
					title: "Vote in progress",
					description: "In everyday life, do you feel invisible?",
					options: ["Yes", "No"],
				},
				action: {
					title: "Upcoming action",
					description: "Casting begins in collaboration with the public in January 2025",
				},
				forum: {
					title: "Project Forum",
					anwsers: "{{n}} anwsers",
					single_anwser: "{{n}} answer",
				},
			},
		},
		terms_of_use: {
			page_title: "Terms of use",
			title: "Terms of use",
			subtitle: "Privacy Policy",
			date: "Effective date: 06/27/2024",
			watch_us: "WATCH'US ('we', 'us', 'our') operates the website https://www.techcannes.com/ and https://www.clapaction.com/",
			collect:
				"This page explains our policies regarding the collection, use and disclosure of personal data when you use our Site and the choices available to you with respect to that data.",
			use_data: "We use your data to provide and improve the Site. By using the Site, you consent to the collection and use of information in accordance with this policy.",
			definitions: {
				title: "Definitions",
				site: "'Site' refers to the website https://www.techcannes.com/ and https://www.clapaction.com/ operated by the company Watch'Us",
				items: [
					"Personal Data: Personal Data means data relating to a living individual who can be identified from such data (or from such data and other information in our possession or likely to come into our possession).",
					"Usage Data: Usage Data is collected automatically and is generated either by use of the Site, or by the Site infrastructure itself (e.g., page view duration).",
					"Cookies : Cookies are small files stored on your device (computer or mobile device).",
					"Data Controller: Data Controller means the natural or legal person (alone, or jointly or in common with other persons) who determines the purposes and manner in which all personal data is processed or is to be processed. For the purposes of this Privacy Policy, we are a Data Controller of your personal data.",
					"Subcontractors (or Service Providers): The term Sub-Contractor (or Service Provider) refers to any natural or legal person who processes data on behalf of the Data Controller. We may use the services of several Service Providers in order to process your data more efficiently",
					"Data Subject (or User): By Data Subject, we mean any living person who uses our Site and is the subject of Personal Data.",
				],
			},
			data_collection_and_use: {
				title: "Data collection and use",
				subtitle: "We collect various types of data for different purposes in order to provide you with our Site and to improve it.",
				types_of_data_collected: {
					title: "Types of data collected",
					personal_data: {
						title: "Personal Data",
						description:
							"When you use our Site, we may ask you to provide certain personally identifiable information that can be used to contact or identify you ('Personal Data'). Personal Data may include, but is not limited to:",
						items: ["E-mail address", "First and last name", "Cookies and Usage Data"],
						use: "We use your Personal Data to send you an answer to your request made via the contact form on our website. We undertake not to send you any promotions or commercial canvassing if you do not give us your consent.",
					},
					usage_data: {
						title: "Usage data",
						description:
							"We may also collect information about how you access and use the Site ('Usage Data'). This Usage Data may include information such as the Internet Protocol address (i.e., IP address) of your computer, browser type, browser version, the pages of our Site that you visit, the date and time of your visit, the time spent on those pages, unique device identifiers, and other diagnostic data.",
					},
					tracking_cookies_data: {
						title: "Tracking and cookie data",
						description: "We use cookies and other similar tracking technologies to track activity on our Site and store certain information.",
						definition:
							"Cookies are low-volume data files that may contain an anonymous unique identifier. Cookies are sent to your browser from a website and are stored on your device.",
						consent: "You can accept or decline cookies directly via the cookie control panel in the bottom right-hand corner of the website.",
					},
				},
				use_of_data: {
					title: "Use of data",
					description: "DCF uses the collected data for various purposes:",
					items: [
						"To provide and service our Site",
						"To provide customer support",
						"To collect valuable data or analysis that will allow us to improve our Site",
						"To monitor the use of our Site",
						"To detect, prevent and resolve technical problems",
						"To respond to any questions or requests from you via the contact form",
					],
				},
			},
			legal_basis: {
				title: "Legal basis for the processing of personal data under the General Data Protection Regulation (GDPR)",
				description:
					"If you reside in the European Economic Area (EEA), La DCF's legal basis for collecting and using the personal data described in this Privacy Policy depends on the Personal Data we collect and the precise context in which we collect it.",
				legal_basis: [
					"Because you have authorized us to do so",
					"Because we have a legitimate interest in carrying out this processing and your rights do not override this legitimate interest",
					"To comply with the law",
				],
			},
			data_retention: {
				title: "Data retention",
				description:
					"Watch'Us will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary for us to perform our legal obligations (e.g. to comply with applicable law), resolve disputes and enforce our agreements and policies. The legal retention period for your Personal Data is a maximum of 3 years",
				usage_data_retention:
					"Watch'Us will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period except where such data is used to enhance the security or improve the functionality of our Site or where we are legally required to retain such data for a longer period. The legal retention period for Usage Data is a maximum of 13 months",
			},
			data_transfer: {
				title: "Data transfer",
				description:
					"Information about you, including your Personal Data, may be transferred from your region, province, country or other territorial division to - and stored on - computers located in a place where data protection legislation differs from that of the territory in which you reside.",
				international_transfer:
					"If you reside outside of France and choose to provide us with information, please be aware that we transfer data, including Personal Data, to France and process it there. By accepting this Privacy Policy and submitting this information, you consent to this transfer",
				security_measures:
					"Watch'Us will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy, and your Personal Data will not be transferred to any organization or country unless adequate controls are in place, including with respect to the security of your data and other personal data.",
			},
			data_disclosure: {
				title: "Data disclosure",
				law_enforcement: {
					title: "Data disclosure to law enforcement agencies",
					description:
						"In certain circumstances, Watch'Us may be required to disclose your Personal Data if required to do so by law or in response to valid requests from public authorities (e.g., a court or government agency).",
				},
				legal_requirements: {
					title: "Legal requirements",
					description: "Watch'Us may disclose your Personal Data if it believes in good faith that this is necessary to:",
					items: [
						"Fulfill a legal obligation",
						"Protect and defend the rights or property of DCF",
						"Prevent or investigate possible wrongdoing in connection with the Site",
						"To ensure the personal safety of users of the Site or the public",
						"Protect against civil liability",
					],
				},
			},
			data_security: {
				title: "Data security",
				description:
					"The security of your data is important to us. However, please remember that no method of data transmission over the Internet or method of electronic storage is 100% secure. Although we strive to use commercially acceptable methods to protect your Personal Data, we cannot guarantee its absolute security.",
			},
			user_rights: {
				title: "Data protection rights granted to you by the General Data Protection Regulation (GDPR)",
				description:
					"If you reside in the European Economic Area (EEA), you have certain data protection rights. DCF intends to take reasonable steps to allow you to correct, amend or delete your Personal Data or limit its use.",
				contact_info: "If you would like to know what Personal Data we hold about you and would like it deleted from our systems, please contact us.",
				rights_list: [
					"The right to access, update or delete the information we hold about you. Where this option is offered, you may consult or update your Personal Data or request its deletion by contacting us.",
					"Right of rectification. You have the right to have your data corrected if it is inaccurate or incomplete",
					"Right to object. You have the right to object to our processing of your Personal Data",
					"Right of limitation. You have the right to ask us to limit the processing of your Personal Data",
					"The right to data portability. You have the right to receive a copy of the information we hold about you in a commonly used, structured and machine-readable format.",
					"Right to withdraw consent. You also have the right to withdraw your consent at any time if La DCF has relied on your consent to process your personal data.",
				],
				proof_of_identity: "Please note that we may ask you to prove your identity before responding to requests of this nature.",
				complaint:
					"You have the right to lodge a complaint with a data protection authority about our collection and use of your Personal Data. For more information, please contact your nearest data protection authority in the European Economic Area (EEA).",
			},
			service_providers: {
				title: "Service providers",
				description:
					"We may use third-party companies and individuals to facilitate the provision of our Site ('Service Providers'), provide the Site on our behalf, provide Site-related services or help us analyze how our Site is used.",
				access_restriction:
					"These third parties have access to your Personal Data only to perform these tasks on our behalf and are prohibited from disclosing or using it for any other purpose.",
			},
			external_links: {
				title: "Links to other sites",
				description:
					"Our Site may contain links to other sites that we do not operate. If you click on a third-party link, you will be redirected to that third party's site. We strongly recommend that you review the privacy policy of each site you visit. We have no control over the content, privacy policies or practices of third-party sites or services and disclaim any responsibility for them.",
			},
			policy_updates: {
				title: "Changes to this Privacy Policy",
				description:
					"We reserve the right to update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.",
				notification: "Before the change takes effect, we will notify you by updating the 'effective date' at the top of this Privacy Policy.",
				advice: "We advise you to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy take effect when they are posted on this page.",
			},
			contact: {
				title: "Contact us",
				description: "If you have any questions about this Privacy Policy or if you have any requests regarding your Personal Data, please contact us:",
				contact_info: {
					contact_person: "Contact DPO : Sarah Llouch",
					email: "By email: watchus13@gmail.com",
					address: "Par voie postale : 15 avenue Hoche, 75008 Paris",
				},
			},
			legal_notice: {
				title: "Legal notice",
				description:
					"This site is created by the company Watch'Us SARL with a capital of 45 000€ (R.C.S. Paris B 482 201 407) located at 15 AV HOCHE 75008 PARIS and chaired by Mrs Sarah Lelouch. Email address: watchus13@gmail.com",
				publication_director: "Directeur de la publication : Mme Sarah Lelouch.",
				hosting_info: {
					company: "The site is hosted by OVH",
					details:
						"SAS au capital de 10 174 560 €\nRCS Lille Métropole 424 761 419 00045\nCode APE 2620Z\nN° TVA : FR 22 424 761 419\nSiège social : 2 rue Kellermann - 59100 Roubaix - France",
					subsidiary:
						"OVH SAS is a subsidiary of OVH Groupe SA, a company registered with the Lille Trade and Companies Register under number 537 407 926 and headquartered at 2 rue Kellermann 59100 Roubaix - France",
					publication_director: "Director of publication: Michel Paulin",
				},
			},
		},
		privacy_policy: {
			page_title: "Privacy Policy",
			title: "Privacy Policy",
			subtitle: "Effective Date: April 19, 2024",
			foreword: {
				title: "Foreword",
				content:
					"This privacy policy formalizes our commitment to the protection of users' privacy on the ClapAction website operated by Watch'US. This policy constitutes the contractual framework between the user and Watch'US.",
			},
			data_protection: {
				title: "Compliance with Data Protection Standards",
				content:
					"We process your personal data in strict compliance with the General Data Protection Regulation (GDPR) of April 27, 2016. We commit to collecting only data that is adequate, relevant, and limited to what is necessary for the services offered, without ever including sensitive data.",
			},
			collect: {
				title: "Collection and Use of Personal Data",
				content:
					"Your data is collected during navigation on the site, account creation, financial transactions, or when you contact us. This data is used exclusively for the management and improvement of our services, as well as for communication related to these services.",
			},
			data_security: {
				title: "Data Retention and Security",
				content:
					"We apply advanced technical and organizational measures to ensure the security and integrity of your personal data. However, we remind you that absolute security is not guaranteed on the Internet. It is your responsibility to also secure your login credentials and passwords.",
			},
			personal_data: {
				title: "Sharing of Personal Data",
				content:
					"We only share your data with third parties for management purposes or to meet legal obligations. No transfer outside the European Union is made without appropriate safeguards such as the European Commission's standard contractual clauses.",
			},
			your_rights: {
				title: "Your Rights",
				content:
					"You have extensive rights over your personal data, including access, correction, deletion, or restriction of its processing. You can exercise these rights by contacting us directly through the means provided in this policy.",
			},
			chart: {
				title: "Policy Changes",
				content:
					"This policy may be updated; we invite you to regularly consult this page to stay informed of changes that may affect the processing of your personal data.",
			},
			contact: {
				title: "Contact and Data Protection Officer",
				content:
					"For any questions or to exercise your rights, you can contact us via the email or postal address provided. If applicable, the contact details of our Data Protection Officer are also provided.",
			},
			appeal: {
				title: "Appeal",
				content: "In case of dispute, you have the right to contact the National Commission for Information Technology and Civil Liberties (CNIL).",
			},
		},
		profile: {
			page_title: "Profile",
			change_avatar: "Change profile picture",
			cancel_button: "Cancel",
			submit_button: "Apply modifications",
			update_success: "Your change has been successfully saved.",
			inputs: {
				firstname: {
					label: "First name",
					placeholder: "Jhon",
				},
				lastname: {
					label: "Last name",
					placeholder: "Doe",
				},
				email: {
					label: "Email",
					placeholder: "jhondoe@gmail.com",
				},
				phone: {
					label: "Phone",
					placeholder: "06 18 24 32 17",
				},
				birthdate: {
					label: "Birthdate",
					placeholder: "24/05/1996",
				},
				city: {
					label: "City",
					placeholder: "Paris",
				},
				address: {
					label: "Address",
					placeholder: "18 rue Maréchal-Juin",
				},
				zipCode: {
					label: "Postal code",
					placeholder: "75 001",
				},
				sex: {
					label: "Sex",
					options: [
						{
							label: "Male",
							value: "homme",
						},
						{
							label: "Female",
							value: "femme",
						},
						{
							label: "Other",
							value: "autre",
						},
					],
				},
				password: {
					label: "Password",
					placeholder: "johndoemotdepasse@78",
					additional_info: "At least 8 characters, including 1 uppercase, 1 lowercase, and 1 number.",
				},
			},
		},
		home: {
			page_title: "Home",
			banner: {
				title: "Your ideas come to life on the",
				inside_title: "big screen!",
				subtitle: "Protect your ideas, get involved, and create the cinema of tomorrow!",
				discover_project: "Discover the projects",
				submit_project: "Submit a project",
			},
			why_join: {
				title: "Why join",
				inside_title: "clapAction ?",
				content:
					"Welcome to clapAction, where your ideas come to life through the power of AI and the unmatched security of blockchain. Whether you are a passionate creator or an ardent supporter of innovative projects, clapAction offers you a unique and secure experience.",
				button: "Create an account",
				creator_title: "Creators",
				creator_content: "Protect your ideas with blockchain technology and, if you wish, share them with film industry professionals.",
				public_title: "Public",
				public_content: "Express your support by voting for the projects that inspire you.",
			},
			marketplace: {
				title: "Coming soon: buy from a collection of authentic",
				inside_title: "certified cinematographic objects",
				subtitle: "Stay connected to be among the first to explore our shop.",
				explore_title: "Explore our shop",
				firstContent: [
					{
						text: "Vous pourrez bientôt acquérir des objets uniques et certifiés, directement liés à des films en tournage ou déjà sortis en salle, diffusés à la télé ou sur des plateformes. Pour les films à venir, ces pièces exclusives seront proposées aux enchères, tandis que pour les films déjà exploités, elles seront disponibles à prix fixe. Une opportunité inédite de vous offrir des décors, costumes, affiches dédicacées, ou tout autre élément iconique du 7ᵉ art.",
					},
					{
						text: "Pour les professionnels du cinéma, mettre en vente ces objets n’est pas seulement un moyen de partager des morceaux d’histoire avec le public, c’est aussi une manière innovante de financer leurs projets en développement.",
					},
					{
						text: "Grâce à ClapAction, ces objets sont associés à un NFT garantissant leur authenticité et leur traçabilité, renforçant ainsi leur valeur auprès des collectionneurs et des cinéphiles. C’est une manière innovante et concrète de donner une seconde vie aux objets tout en contribuant à écrire l’avenir du cinéma.",
					},
					{
						text: "La valeur de ces objets ne cessera de croître avec le temps, en fonction du succès des films, de leur impact culturel ou des carrières des artistes associés. Posséder un costume, un décor ou une affiche d’un film culte ou d’un acteur devenu légendaire, c’est non seulement acquérir une œuvre intemporelle, mais aussi investir dans un patrimoine à forte valeur émotionnelle et financière.",
					},
					{
						text: "Avec clapAction, vous avez accès à la toute première Marketplace au monde dédiée à la vente d’objets de cinéma, de manière fiable, sécurisée, et avec une garantie d’authenticité. Bien plus qu’un simple achat, c’est une immersion dans la création cinématographique et un soutien direct aux artistes de demain.",
					},
					{
						text: "Si vous êtes un professionnel et souhaitez faire parti des premiers à mettre en vente l’un de vos objets lié à l’une de vos productions dès le mois de février prochain, contactez-nous : contact@clapaction.com",
					},
				],
				explore_content: "Dive into our marketplace dedicated to cinema enthusiasts.",
				certified_title: "Certified items",
				certified_content: "A wide selection of authentic and certified items on the blockchain.",
			},
			featured_projects: {
				title: "Projects in",
				inside_title: "development",
				subtitle: "Explore the captivating stories and unique worlds of projects supported by clapAction.",
				discover_project: "Discover the projects",
			},
			storytelling: {
				first_block: {
					title: "Enthusiasts: Join the",
					inside_title: "creation",
					title_2: "and the",
					inside_title_2: "production of films",
					subtitle:
						"Vote to contribute step by step to the production of films: from development (writing, casting...) to release, including shooting and post-production. Interact with the community and enjoy exclusive benefits: access to premieres, sets, and your favorite artists.",
				},
				second_block: {
					title: "Share your ideas",
					inside_title: "securely",
					subtitle:
						"Your documents are anchored on the blockchain, ensuring the complete preservation and security of your creation. Whether you are professionals or amateurs, share your ideas with the assurance that they will be carefully protected for life.",
					additionnal_subtitle: "Note: you can choose to protect your ideas and keep them confidential or submit them to our selection committee.",
				},
				third_block: {
					title: "Professionals: Consult",
					inside_title: "public opinion",
					title_2: "at any time",
					subtitle: "Create a community around your projects, access an opinion barometer, and submit your productions to public votes to guide you in your development.",
				},
			},
			achivement: {
				title: "Our",
				inside_title: "flagship projects",
				subtitle: "The One Who Hadn't Seen Friends",
				content:
					"Greg meets his future wife Rachel a few minutes before the ceremony. She is so beautiful, he wants to say 'Oh my God!' in reference to a character in the series Friends. Rachel doesn't understand his joke, she has never seen the series. Greg is shocked, he doesn't want to get married anymore.",
			},
			FAQ: {
				title: "Frequently Asked Questions",
				questions: [
					{
						title: "How can I submit a movie idea, synopsis, or script ?",
						content: "Create an account, fill out the submission form, and upload your PDF if you have one.",
					},
					{
						title: "What are the criteria for selecting projects ?",
						content: "Projects are selected based on their originality, feasibility, and exploitation potential.",
					},
					{
						title: "How is my project reviewed when submitted, especially if you are seeking development assistance ?",
						content:
							"Your project is first reviewed by the internal Reading Committee of LaDCF. If selected, it is then presented to the Selection Committee (comprising 10 recognized figures) who decide which films will receive development funding and professional guidance. Discover the LaDCF Selection Committee.",
					},
					{
						title: "How are the Reading Committee results displayed ?",
						content: "You can track the status of your project on the “my projects” page, using three icons: “selected,” “not selected,” or “under review.”",
					},
					{
						title: " What is the response time of the Reading Committee ?",
						content:
							"There is no specified timeframe by the Reading Committee. On average, it takes one month for a project to be reviewed, but this can vary depending on the number of submissions received each week. For reference, the Selection Committee meets quarterly.",
					},
					{
						title: "How can I vote for a project ?",
						content: "Log in to your account, navigate to the project page, and vote using the available options.",
					},
					{
						title: "Is there a limit on the number of project submissions ?",
						content:
							"There is no limit to the number of projects you can submit on the platform. You can submit as many projects as you want, and they will benefit from blockchain authentication. All project submissions have legal value.",
					},
					{
						title: "Can a submitted project be modified or deleted ?",
						content:
							"As long as the project is not anchored on the blockchain, it can be modified. Once anchored, it will be permanently recorded on the blockchain and can no longer be modified or deleted.",
					},
					{
						title: "Can I submit a video with my project ?",
						content: "You cannot directly upload videos to the platform, but you can include a link to the video in your synopsis or CV.",
					},
					{
						title: "Does the platform help individuals find a producer ?",
						content:
							"If you submit a project as an individual, it will automatically be reviewed by the LaDCF Committee. If you are an author, director, or producer, you are categorized as a professional. By publishing your project on the platform, you can connect with producers, broadcasters, and distributors through the professional space, interact with the public, and build a community around your project starting from the development stage.",
					},
				],
			},
		},
		confirmation_code: {
			page_title: "Code confirmation",
			title: "Authentification code",
			subtitle: "Enter the OTP code we have sent to your registered email address",
			button: "Log in",
			send_code: "Send a new code",
		},
		my_projects: {
			page_title: "Project name",
			title: "My Submitted Projects",
			filters: {
				all_projects: "All projects",
				accepted: "Retained",
				refused: "Not retained",
				studying: "Studying",
				archived: "Archived",
				anchored: "Anchored",
				not_anchored: "Pending anchoring",
				anchor_failed: "Anchoring failed",
			},
			table: {
				headers: {
					project_name: "Project name",
					ai_image: "IA image",
					anchor_proof: "Anchor proof",
					status: "Status",
					date: "Date",
				},
				waiting: "Waiting...",
				see_image: "See image",
				anchor_link: "Anchor link",
				download: "Download",
				anchor: "Anchor",
				menu: {
					anchor: "Anchor",
					download_proof: "Download proof",
					data: "Data",
					edit: "Edit",
				},
			},
			drawer: {
				button: {
					anchor: "Anchor",
					modify: "Modify",
					modify_socials: "Modify socials",
					download_proof: "Download proof",
				},
				modal: {
					title: "Are you sure you want to anchor this project?",
					content1:
						"Anchoring is a permanent action that locks your project in its current state. Once you have anchored the project, you will no longer be able to modify its contents. This operation is irreversible and guarantees that the data cannot be altered subsequently.",
					content2: "Please ensure all necessary changes have been made before confirming this action.",
					button: {
						confirm: "Anchor",
						cancel: "Cancel",
					},
				},
			},
		},
		submit_project: {
			page_title: "Submit a project",
			title: "Submit a project",
			subtitle: "protected, secure, and certified",
			description: {
				items: [
					{
						text: "Share your ideas with confidence by filling in the fields below.",
					},
					{
						text: "Thanks to our technology, each submitted project is automatically <b>anchored on the blockchain</b>. Once your project is anchored, you will receive a certificate of authenticity to ensure the protection of your creation, regardless of the reason for your submission."
					},
					{
						text: "Additionally, a movie poster generated by our Artificial Intelligence will be offered to you exclusively. An inspiring tool designed to bring your vision to life."
					},
					{
						text: "Your project remains <b>strictly confidential</b>: no information will be shared without your consent."
					}
				],
			},
			labels: {
				project_title: "Project Title",
				project_title_placeholder: "Maximum 100 characters",
				project_format: "Format",
				project_reason: "What is the main reason you are submitting your project?",
				project_pitch: "Pitch",
				project_pitch_additionnal: "Please provide a concise and appealing description. This description will be used to highlight your project on the platform.",
				project_pitch_placeholder: "Pitch (minimum 150 characters)",
				project_linkedin: "Please provide a LinkedIn link if you don't provide your CV",
				cv: "CV",
				scenario: "Synopsis and/or Artistic Elements",
			},
			selections: {
				format: {
					selectAnOption: "Select a format",
					long_metrage: "Feature Film",
					court_metrage: "Short Film",
					serie: "Series",
					documentaire: "Documentary",
				},
				reason: {
					protect: "I am submitting my project simply to protect it",
					protect_and_development_aid: "I am submitting my project to protect it and submit it for development assistance",
				},
				purposes: {
					funding: "Submit my project to the selection committee of LaDCF (La Diversité du Cinéma Français)",
					funding_comment: "(To apply for development assistance)",
					protect: "Obtain a certificate of authenticity and protect my idea",
					pitch: "Participate in a selection to present my project to professionals with Neeedco",
				},
			},
			drag_and_drop: {
				cv: "Drag or click here to upload your CV",
				scenario: "Drag or click here to upload your document",
			},
			confidentiality: {
				scenarioProtection: {
					text: "Note : By submitting your project on clapAction, the elements of your project (script, synopsis, file, etc.) will be protected on the blockchain. If you want your project to be reviewed by the Selection Committee, we encourage you to limit your presentation to two typed pages.",
				},
				projectSharing: {
					text: "If you wish to submit your project to share it publicly with the community, please visit the ",
					suscribePageLink: "dedicated space for professionals.",
				},
			},
			send_button: "Submit the project",
		},
		edit_project: {
			page_title: "Edit a project",
			title: "Edit a project",
			subtitle: "Modify the fields below to update your project.",
			labels: {
				project_title: "Project Title",
				project_format: "Format",
				project_reason: "What is the main reason you are submitting your project?",
				project_pitch: "Pitch",
				project_pitch_additionnal: "Please provide a concise and appealing description. This description will be used to highlight your project on the platform.",
				project_pitch_placeholder: "Pitch (minimum 150 characters)",
				project_linkedin: "Please provide a LinkedIn link if you don't provide your CV",
				cv: "CV",
				scenario: "Synopsis and/or Artistic Elements",
				purpose: "Why are you submitting your project? (You can check multiple options)",
			},
			selections: {
				format: {
					long_metrage: "Feature Film",
					court_metrage: "Short Film",
					serie: "Series",
					documentaire: "Documentary",
				},
				reason: {
					protect: "I am submitting my project simply to protect it",
					protect_and_development_aid: "I am submitting my project to protect it and submit it for development assistance",
				},
			},
			drag_and_drop: {
				cv: "Drag or click here to upload your CV",
				scenario: "Drag or click here to upload your document",
			},
			confidentiality:
				"Note: By submitting your project on clapAction, the script will be protected on the blockchain. If you want your project to be reviewed by the Selection Committee, please do not exceed 2 typed pages.",
			send_button: "Edit the project",
		},
		contact: {
			page_title: "Contact",
			title: "Contact us",
			subtitle: "Need information? Send us your questions or comments, we will respond quickly!",
			labels: {
				subject: "Subject",
				email: "Email",
				message: "Message",
				firstname: "First name",
				lastname: "Last name",
				phone: "Phone",
			},
			confirmation: "Thank you for your message, we will respond as soon as possible.",
			button: "Send",
		},
		subscribe: {
			page_title: "Subscribe",
			title: "Get to know your audience and",
			inside_title: "discover their expectations!",
			box: "Are you a professional?",
			subtitle: "Get valuable insights on your audience and influence the industry",
			project_call: {
				title: "Call for projects",
				description: "Until January 30th, clapAction offers its services to about fifteen professional projects! Send your projects to contact@clapaction.com to benefit from the platform's features for free.",
			},
			participate: {
				title: "ClapAction Award!",
				p1: "As part of the <b>World AI Film Festival (WAiFF)</b> on April 11-12, 2025, in Nice, experience a one-of-a-kind event:",
				p2: "The first global festival dedicated to works created with artificial intelligence, celebrating technological innovation in the service of art while championing the talents of tomorrow.",
				p3: "The WAiFF will present <b>4 prestigious awards</b>, including the <b>ClapAction Award</b>:",
				ul: [
					"This award honors the best short film (3 to 4 minutes) created entirely on a smartphone.",
					"All stages—shooting, editing, mixing, and color grading—must be completed exclusively on a mobile device.",
				],
				learn_more: {
					txt: "Learn more",
					link: "https://www.techcannes.com/en#evenements",
				},
			},
			description: {
				title: "Are you developing films?",
				content1:
					"Create a community around your projects with clapAction! Discover the expectations of the public, involve them in your development and production process, until the release of your film. Subscribe now! Starting in October, you will be able to share exclusively the projects that drive you today to make them the successes of tomorrow. Anticipation is the key to curiosity and happiness... clapAction is one of them.",
				content2: "TBD",
			},
			button_box: {
				title: "Subscribe now!",
				content1: "From next June, you will be able to share exclusively the projects that drive you today to make them the successes of tomorrow.",
				content2: "Waiting is the key to happiness… clapAction is one of them.",
				button: "Subscribe",
			},
			abilities_title: "Professionals can do :",
			deposit:
				"Submit their film(s) on the platform at any stage of its production (from development to release) in order to create a community and consult it as soon as they wish",
			federate: "Federate and engage a community around each project, starting by taking advantage of the entire clapAction community.",
			contribute:
				"Ask the public for any subject/question (votes, search for ideas, call for testimonials related to their subject, help with casting, choice of title, posters, music, etc.)",
			use_data: "Have access to all data related to their project.",
			deposit_content: "Upload and share the content of their choice related to their project(s): videos, behind the scenes, ITV, reels, etc.",
			offer: "Offer, if the project already has them, direct links to their social networks.",
			drive: "Support the creation of each community, in a unique way adapted to their subject.",
			shape: "Shape their projects according to public expectations",
			types: {
				title: "Types :",
				type1: "Gender (Man/Woman)",
				type2: "Age range",
				type3: "Places of residence",
				type4: "Kind of movies they like",
				type5: "Content created by their community, linked to their project",
				type6: "Percentage of interest in their project compared to all films on clapAction",
			},
			contact: "Contact us",
			radio: {
				title: "You want to",
				options: {
					build: "Share a film, build and interact with its community, and get to know it",
					access: "Share several films and have access to all data and public expectations",
				},
			},
			confirmation: "Thank you for your message, we will respond as soon as possible.",
			labels: {
				firstname: "First name",
				lastname: "Last name",
				address: "Address",
				phone: "Phone",
				email: "Email",
				message: "Do you have a question? Send us a message",
			},
			button: "Send",
		},
		dashboard: {
			page_title: "Dashboard",
		},
		reset_password: {
			page_title: "Resetting the password",
			title: {
				first: "Resetting the",
				second: "Password",
			},
			subtitle: "Please enter a new password",
			labels: {
				password: "Password",
				password_additionnal: "At least 8 characters",
				password_confirm: "Confirm password",
			},
			reset_button: "Change password",
		},
		legal_notice: {
			page_title: "Legal information",
			title: "Legal information",
			subtitle:
				"In accordance with the provisions of Law No. 2004-575 of 21 June 2004 on confidence in the digital economy, users of the clapAction website are informed of the identity of the various parties involved in its creation and monitoring.",
			edition: {
				title: "Edition du site",
				url: "This site, accessible at the URL https://clapaction.app/ (the Site), is published by :",
				company:
					"CLAPACTION, a company with share capital of 1000 euros, registered with the Paris Trade and Companies Register under number 934 285 610 R.C.S. Paris, with its registered office at 28 RUE DE TURIN, 75008 PARIS, represented by Sarah Lelouch, duly authorised.",
				tva: "The publisher's individual VAT number is: FR35934285610.",
			},
			hosting: {
				title: "Hosting",
				hosted: "The Site is hosted by OVH SAS, located at 2 rue Kellermann - BP 80157 - 59053 Roubaix Cedex 1, (telephone contact or email: 1007).",
			},
			publication: {
				title: "Director of publication",
				content: "The Site's Publication Director is Sarah Lelouch.",
			},
			contact: {
				title: "Contact us",
				mail: "By e-mail: contact@clapaction.com",
				letter: "By post: 15 AVENUE HOCHE, 75008 PARIS",
				legal: "Legal Notices by clapAction",
			},
		},
		forgot_password: {
			page_title: "Forgot your password?",
			title: "Forgot your password?",
			labels: {
				email: "Email",
				email_additionnal: "*We will send you a link by email to reset your password.",
			},
			confirmation: "An email has been sent to you with a password reset code.",
			send_button: "Send link by email",
			redirect_link: "Back to",
		},
		login: {
			page_title: "Sign in",
			title: "Sign in",
			subtitle: "",
			labels: {
				email: "Email",
				password: "Password",
			},
			password_forgotten: "Forgot your password?",
			login_button: "Sign in",
			redirect_text: "Don't have an account?",
			redirect_link: "Sign up",
		},
		projects: {
			page_title: "Projects",
			title: "Discover the films and series selected by",
			inside_title: "our Committee",
		},
		complete_profile: {
			page_title: "Complete your profile",
			title: "Before submitting a project, complete your profile",
			labels: {
				firstname: "First name",
				lastname: "Last name",
				pseudo: "Pseudo",
				email: "Email",
				password: "Password",
				password_confirmation: "Confirm password",
				password_additionnal: "At least 8 characters",
				checkbox: "I accept the terms of use",
				zipcode: "Postal code",
				city: "City",
				address: "Address",
				phoneNumber: "Phone",
			},
			next_button: "Next",
		},
		register: {
			page_title: "Sign up",
			title: "Sign up",
			subtitle:
				"Join clapAction: submit your film or series ideas in complete confidence, and take part in the creation of clapAction projects. All fields below are mandatory.",
			labels: {
				firstname: "First name",
				lastname: "Last name",
				pseudo: "Pseudo",
				email: "Email",
				password: "Password",
				password_confirmation: "Confirm password",
				password_additionnal: "At least 8 characters",
				checkbox: "I accept the terms of use",
				zipcode: "Postal code",
				city: "City",
				address: "Address",
				phoneNumber: "Phone",
			},
			register_button: "Sign up",
			redirect_text: "Already have an account?",
			redirect_link: "Log in",
		},
		post: {
			return: "Back",
			title: "Forum",
			comment: {
				submit: "Publish",
				placeholder: "Share your opinion...",
				default_subtitle: "Let's discuss together",
				empty: "No comments",
				share: "Be the first to share your opinion!",
				reply: {
					delete: "Delete",
					submit: "Reply",
					many_replies: "replies",
					single_reply: "reply",
					your_reply: "Your reply...",
				}
			},
		},
		prix_alice_guy: {
			page_title: "Prix Alice Guy",
			header: {
				publication: "Publication: December 1, 2024 to January 31, 2025",
				genre: "Genre: Director's films",
				format: "Format: Vote",
			},
			content: {
				title: "Vote for the Alice Guy 2025 Award",
				description: `Since 2018, the Alice Guy Prize has been awarded to the best French film directed by a woman and released in cinemas during the year. Anyone can help choose it. How? By voting for your 5 favorite films from the list below.`,
				sub_description: "You have until January 31, 2025 to make your choice.",
			},
			buttons: {
				vote: "Take part in the vote",
				discover: "Discover the Alice Guy page"
			},
			sections: {
				first: {
					title: "Voting is simple.",
					description: `All eligible films <strong>(85 this year)</strong> are included in the list provided. Click on “I vote” and tick a minimum of 3 films and a maximum of 5 for your vote to be recorded. Everyone can vote... but each person can only vote once. To validate your choice, simply leave your email address. `,
					sub_description: "The virtual ballot box will close at midnight on January 31, 2025.",
				},
				second: {
					title: "What’s Next?",
					list: {
						first: "On February 1, 2025, the five films that receive the most votes (1 vote = 1 count) will be announced. At the end of February, they will be evaluated by a gender-balanced jury of six film professionals.",
						second: "An award ceremony for the Alice Guy Prize will then be organized in Paris to honor the winner, the memory and work of Alice Guy—the world’s first female director—and the 130th anniversary of cinema. As every year, the event will be open to everyone."
					}
				},
			},
		},
	},
	component: {
		theme_selector: {
			title: "Genre",
			select: "Select the corresponding genre",
			themes: {
				comedie: "Comedy",
				comedie_romantique: "Romantic comedy",
				comedie_dramatique: "Dramatic comedy",
				biopic: "Biopic",
				thriller: "Thriller",
				film_daction: "Action film",
				film_policier: "Detective film",
				film_dhorreur: "Horror film",
				film_historique: "Historical film",
				science_fiction: "Science fiction",
				film_danimation: "Animated Film",
				comedie_musicale: "Musical comedy",
				dramédie: "Drama",
				comedie_absurde: "Absurd comedy",
				thriller_psychologique: "Psychological thriller",
			},
		},
		featured_project: {
			project_leader: "Project leader:",
			published_at: "Published on: {{var1}}",
			category: "Genre:",
			length: "Format:",
			button: "See the project",
		},
		drag_and_drop_file: {
			no_file_selected: "No file selected",
		},
		authpagetemplate: {
			footer: {
				copyright: "© 2024 clapAction",
			},
		},
		lang_selector: {
			fr: "FR",
			en: "EN",
		},
		header: {
			login: "Sign in",
			register: "Sign up",
			submit_project: "Submit a project",
			my_projects: "My submitted projects",
			personnal_informations: "Personal information",
			home: "Home",
			marketplace: "Marketplace",
			projects: "The projects",
			contact: "Contact",
			subscribe: "Professionals",
			all_projects: "All projects",
			logout: "Log out",
			coming_soon: {
				title: "Vote for the Alice Guy Prize 2025",
				message: "Since 2018, the Alice Guy Prize has honored the best French film directed by a woman.",
				submessage: "Join in by voting for your 5 favorite films before January 31, 2025.",
				button: "Take part in the vote",
			},
		},
		footer: {
			social_links: "Social Links",
			neeedco: "The social network of cinema",
			dcf: "The selection committee",
			contact: "Contact",
			legal_notice: "Legal Notice",
			privacy_policy: "Privacy Policy",
			terms_of_use: "Terms of Use",
			copyright: "Copyright © {{year}} clapAction"
		},
		suscribe: {
			suscribe_newsletter: "Subscribe to our newsletter",
			suscribed_newsletter: "You are now subscribed to clapAction newsletter.",
		}
	},
	enums: {
		EValidationErrors: EValidationErrorImplementation,
		EProjectThemes: EProjectThemeImplementation,
		EProjectFormats: EProjectFormatImplementation,
		EProjectReasons: EProjectReasonImplementation,
		EProjectPurposes: EProjectPurposeImplementation,
	},
};

export default trads;
