import Typography from "components/elements/Typography";
import classes from "./classes.module.scss";
import I18n from "components/materials/I18n";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "components/elements/Button";
import EllipsisImage from "assets/images/ellipsis-design.png";
import ModuleConfig from "configs/ModuleConfig";
import { container } from "tsyringe";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useContext } from "react";
import { UserContext } from "contexts/User";
import SuscribeNewsLetterButton from "components/materials/SuscribeNewsLetterButton";

const modules = container.resolve(ModuleConfig).get().modules;

export default function Banner() {
	const navigate = useNavigate();
	const { user } = useContext(UserContext);

	const redirectTo = useCallback(() => {
		if (user) {
			navigate(modules.pages.SubmitProject.props.path);
		} else {
			navigate(modules.pages.Login.props.path);
		}
	}, [navigate, user]);

	return (
		<section className={classes["root"]}>
			<div className={classes["container"]}>
				<div className={classes["content"]}>
					<div className={classes["title-container"]}>
						<Typography type="h1" weight="bold">
							{I18n.trslt(I18n.asset.pages.home.banner.title)}
							<Typography type="span" weight="bold" color="secondary" className={classes["inside-title"]}>
								&nbsp;{I18n.trslt(I18n.asset.pages.home.banner.inside_title)}
							</Typography>
						</Typography>

						<Typography type="p" size="large" weight="regular">
							{I18n.trslt(I18n.asset.pages.home.banner.subtitle)}
						</Typography>
					</div>

					<div className={classes["buttons-container"]}>
						<Link to={modules.pages.Projects.props.path}>
							<Button variant={EButtonVariant.CONTAINED} color={EButtonColor.PRIMARY} size={EButtonSize.LARGE}>
								{I18n.trslt(I18n.asset.pages.home.banner.discover_project)}
							</Button>
						</Link>

						<Button variant={EButtonVariant.OUTLINED} color={EButtonColor.PRIMARY} size={EButtonSize.LARGE} onClick={redirectTo}>
							{I18n.trslt(I18n.asset.pages.home.banner.submit_project)}
						</Button>
					</div>
				</div>
				<SuscribeNewsLetterButton />
			</div>

			<div className={classes["design"]}>
				<img src={EllipsisImage} alt="Ellipsis design" className={classes["image"]} />
			</div>
		</section>
	);
}
