import EllipsisImage from "assets/images/ellipsis-design.png";
import { EProjectFormat } from "common/enums/ProjectFormat/ProjectFormat";
import { EProjectTheme } from "common/enums/ProjectTheme/ProjectTheme";
import ProjectUpdateRequestResource from "common/resources/Project/ProjectUpdateRequestResource";
import ProjectUpdateSocialsRequestResource from "common/resources/Project/ProjectUpdateSocialsRequestResource";
import { ValidationError } from "common/resources/Resource";
import Button, { EButtonColor, EButtonSize, EButtonVariant } from "components/elements/Button";
import Typography from "components/elements/Typography";
import Form from "components/materials/Form";
import DragAndDropFile from "components/materials/Form/DragAndDropFileElement";
import Input from "components/materials/Form/InputElement";
import Select from "components/materials/Form/SelectElement";
import TextArea from "components/materials/Form/TextAreaElement";
import Checkbox from "components/elements/Checkbox";
import I18n from "components/materials/I18n";
import ThemeSelector from "components/materials/ThemeSelector";
import PageTemplate from "components/PageTemplate";
import ModuleConfig from "configs/ModuleConfig";
import { UserContext } from "contexts/User";
import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProjectService from "services/ProjectService";
import { container } from "tsyringe";
import classes from "./classes.module.scss";
import ProjectResponseResource from "common/resources/Project/ProjectResponseResource";
import { EProjectPurpose } from "common/enums/ProjectPurpose";
import assert from "assert";

const projectService = container.resolve(ProjectService);
const modules = container.resolve(ModuleConfig).get().modules;

type IProps = {
	project: ProjectResponseResource;
	editSocialsOnly?: boolean;
};

export default function ProjectForm(props: IProps) {
	const navigate = useNavigate();
	const [errors, setErrors] = useState<ValidationError[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isCvAdded, setIsCvAdded] = useState<boolean>(false);
	const [selectedTheme, setSelectedTheme] = useState<EProjectTheme | null>(null);
	const [selectedPurposes, setSelectedPurposes] = useState<EProjectPurpose[]>([]);
	const { user } = useContext(UserContext);

	useEffect(() => {
		if (!user?.address || !user?.city || !user?.zipCode || !user?.phoneNumber || !user?.gender || !user?.birthDate) {
			navigate(modules.pages.CompleteProfile.props.path);
		}
	}, [navigate, user]);

	const onThemeSelect = useCallback((theme: EProjectTheme) => {
		setSelectedTheme(theme);
	}, []);

	const onSubmitProject = useCallback(
		(event: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			event.preventDefault();
			setErrors([]);
			setIsLoading(true);

			assert(selectedTheme, "No selected theme");
			assert(selectedPurposes.length > 0, "No selected purpose");

			const projectUpdateRequest = ProjectUpdateRequestResource.hydrate<ProjectUpdateRequestResource>({
				title: formData["title"] as string,
				pitch: formData["pitch"] as string,
				format: formData["format"] as EProjectFormat,
				discord: formData["discord"] ? (formData["discord"] as string) : undefined,
				instagram: formData["instagram"] ? (formData["instagram"] as string) : undefined,
				x: formData["x"] ? (formData["x"] as string) : undefined,
				linkedin: formData["linkedin"] ? (formData["linkedin"] as string) : undefined,
				purposes: selectedPurposes,
				theme: selectedTheme,
				lang: I18n.getLang(),
			});


			const scenario = (formData["scenario"] as FileList)?.[0];
			const cv = (formData["cv"] as FileList)?.[0];

			if (!cv && !props.project.cv && !formData["linkedin"]) {
				setErrors([{ property: "linkedin", constraints: { isNotEmpty: I18n.trslt(I18n.asset.pages.edit_project.labels.project_linkedin) } }]);
				setIsLoading(false);
				return;
			}

			projectUpdateRequest
				.validateOrReject()
				.then(() => {
					projectService
						.updateProject(props.project.id, projectUpdateRequest, { scenario, cv })
						.then(() => {
							navigate(modules.pages.MyProjects.props.path);
						})
						.catch((error) => {
							setIsLoading(false);
							console.error({ error });
							if (error instanceof Array) {
								setErrors(error);
							}
						});
				})
				.catch((error) => {
					setIsLoading(false);
					console.error({ error });
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[selectedTheme, selectedPurposes, props.project.cv, props.project.id, navigate],
	);

	const onSubmitSocials = useCallback(
		(event: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			event.preventDefault();
			setErrors([]);
			setIsLoading(true);

			const projectUpdateSocialsRequest = ProjectUpdateSocialsRequestResource.hydrate<ProjectUpdateSocialsRequestResource>({
				discord: formData["discord"] ? (formData["discord"] as string) : undefined,
				instagram: formData["instagram"] ? (formData["instagram"] as string) : undefined,
				x: formData["x"] ? (formData["x"] as string) : undefined,
				linkedin: formData["linkedin"] ? (formData["linkedin"] as string) : undefined,
			});

			projectUpdateSocialsRequest
				.validateOrReject()
				.then(() => {
					projectService
						.updateProjectSocials(props.project.id, projectUpdateSocialsRequest)
						.then(() => {
							navigate(modules.pages.MyProjects.props.path);
						})
						.catch((error) => {
							setIsLoading(false);
							console.error({ error });
							if (error instanceof Array) {
								setErrors(error);
							}
						});
				})
				.catch((error) => {
					setIsLoading(false);
					console.error({ error });
					if (error instanceof Array) {
						setErrors(error);
					}
				});
		},
		[props.project.id, navigate],
	);

	const onSubmit = useCallback(
		(event: React.FormEvent<HTMLFormElement>, formData: { [key: string]: unknown }) => {
			props.editSocialsOnly ? onSubmitSocials(event, formData) : onSubmitProject(event, formData);
		},
		[props.editSocialsOnly, onSubmitSocials, onSubmitProject],
	);

	const canSubmit = useCallback(() => {
		return !!selectedTheme && selectedPurposes.length > 0;
	}, [selectedTheme, selectedPurposes]);

	const addSelectedPurpose = useCallback((purpose: EProjectPurpose) => {
		setSelectedPurposes((purposes: EProjectPurpose[]) => {
			if (!purposes.includes(purpose)) {
				return [...purposes, purpose];
			}
			return purposes;
		});
	}, []);

	const removeSelectedPurpose = useCallback((purpose: EProjectPurpose) => {
		setSelectedPurposes((purposes: EProjectPurpose[]) => {
			return purposes.filter((p) => p !== purpose);
		});
	}, []);

	const handlePurposeChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>, purpose: EProjectPurpose) => {
			if (e.currentTarget.checked) {
				addSelectedPurpose(purpose);
			} else {
				removeSelectedPurpose(purpose);
			}
		},
		[addSelectedPurpose, removeSelectedPurpose],
	);

	useEffect(() => {
		setSelectedTheme(props.project.theme);
		props.project.purpose.map(({purpose}) => addSelectedPurpose(purpose));
	}, [props.project.purpose, props.project.format, props.project.theme, addSelectedPurpose]);

	return (
		<PageTemplate tabTitle={I18n.trslt(I18n.asset.pages.edit_project.page_title)} mainSectionClassName={classes["root"]}>
			<div className={classes["content"]}>
				<div className={classes["title-container"]}>
					<Typography type="h1" weight="bold">
						{I18n.trslt(I18n.asset.pages.edit_project.title)}
					</Typography>
					<Typography type="p" weight="regular" size="medium">
						{I18n.trslt(I18n.asset.pages.edit_project.subtitle)}
					</Typography>
				</div>

				<Form onSubmit={onSubmit} errors={errors} className={classes["form-container"]}>
					<div className={classes["inputs-container"]}>
						<Input
							name="title"
							required
							className={classes["input"]}
							inputClassName={classes["input-content"]}
							label={I18n.trslt(I18n.asset.pages.edit_project.labels.project_title)}
							defaultValue={props.project.title}
							disabled={props.editSocialsOnly}
						/>
						<ThemeSelector onSelect={onThemeSelect} selectedTheme={selectedTheme} />

						<Select
							className={classes["select"]}
							name="format"
							label={I18n.trslt(I18n.asset.pages.edit_project.labels.project_format)}
							options={[
								{ label: I18n.trslt(I18n.asset.pages.edit_project.selections.format.long_metrage), value: EProjectFormat.long_metrage },
								{ label: I18n.trslt(I18n.asset.pages.edit_project.selections.format.court_metrage), value: EProjectFormat.court_metrage },
								{ label: I18n.trslt(I18n.asset.pages.edit_project.selections.format.serie), value: EProjectFormat.serie },
								{ label: I18n.trslt(I18n.asset.pages.edit_project.selections.format.documentaire), value: EProjectFormat.documentaire },
							]}
							required
							disabled={props.editSocialsOnly}
						/>

						<Typography type="p" weight="regular" size="medium">
							{I18n.trslt(I18n.asset.pages.edit_project.labels.purpose)}
							{"*"}
						</Typography>

						<Checkbox
							checked={selectedPurposes.includes(EProjectPurpose.AUTHENTICITY_PROTECTION)}
							uid="AUTHENTICITY_PROTECTION"
							label={I18n.trslt(I18n.asset.pages.submit_project.selections.purposes.protect)}
							onChange={(e) => handlePurposeChange(e, EProjectPurpose.AUTHENTICITY_PROTECTION)}
						/>
						<Checkbox
							checked={selectedPurposes.includes(EProjectPurpose.FUNDING_APPLICATION)}
							uid="FUNDING_APPLICATION"
							label={I18n.trslt(I18n.asset.pages.submit_project.selections.purposes.funding)}
							onChange={(e) => handlePurposeChange(e, EProjectPurpose.FUNDING_APPLICATION)}
						/>
						<Checkbox
							checked={selectedPurposes.includes(EProjectPurpose.PITCH_TO_PROFESSIONALS)}
							uid="PITCH_TO_PROFESSIONALS"
							label={I18n.trslt(I18n.asset.pages.submit_project.selections.purposes.pitch)}
							onChange={(e) => handlePurposeChange(e, EProjectPurpose.PITCH_TO_PROFESSIONALS)}
						/>

						<TextArea
							name="pitch"
							className={classes["textarea"]}
							label={I18n.trslt(I18n.asset.pages.edit_project.labels.project_pitch)}
							additionalInfo={I18n.trslt(I18n.asset.pages.edit_project.labels.project_pitch_additionnal)}
							defaultValue={props.project.pitch}
							disabled={props.editSocialsOnly}
						/>

						<Input name="discord" className={classes["input"]} label={"Discord"} defaultValue={props.project.discord} inputClassName={classes["input-content"]} />

						<Input name="instagram" className={classes["input"]} label={"Instagram"} defaultValue={props.project.instagram} inputClassName={classes["input-content"]} />

						<Input name="x" className={classes["input"]} label={"X"} defaultValue={props.project.x} inputClassName={classes["input-content"]} />
						<Input
							name="linkedin"
							className={classes["input"]}
							inputClassName={classes["input-content"]}
							label={"Linkedin"}
							defaultValue={props.project.linkedin}
							required={!(props.project.cv?.name || isCvAdded)}
							additionalInfo={I18n.trslt(I18n.asset.pages.edit_project.labels.project_linkedin)}
						/>

						<DragAndDropFile
							name="cv"
							accept={[".pdf"]}
							description={I18n.trslt(I18n.asset.pages.edit_project.drag_and_drop.cv)}
							label={I18n.trslt(I18n.asset.pages.edit_project.labels.cv)}
							existingFileName={props.project.cv?.name}
							disabled={props.editSocialsOnly}
							onDrop={() => setIsCvAdded(true)}
							onFileChange={() => setIsCvAdded(true)}
						/>
						<DragAndDropFile
							name="scenario"
							accept={[".pdf"]}
							description={I18n.trslt(I18n.asset.pages.edit_project.drag_and_drop.scenario)}
							label={I18n.trslt(I18n.asset.pages.edit_project.labels.scenario)}
							keyErrorName={"sha256"}
							existingFileName={props.project.scenario?.file?.name}
							disabled={props.editSocialsOnly}
						/>

						<Typography type="p" weight="regular" size="xsmall">
							{I18n.trslt(I18n.asset.pages.edit_project.confidentiality)}
						</Typography>
					</div>

					<Button
						variant={EButtonVariant.CONTAINED}
						color={EButtonColor.PRIMARY}
						size={EButtonSize.LARGE}
						type="submit"
						isLoading={isLoading}
						fullwidth
						disabled={!canSubmit()}>
						<Typography type="span" size="medium" weight="medium">
							{I18n.trslt(I18n.asset.pages.edit_project.send_button)}
						</Typography>
					</Button>
				</Form>
			</div>
			<div className={classes["design"]}>
				<img src={EllipsisImage} alt="Ellipsis design" className={classes["image"]} />
			</div>
		</PageTemplate>
	);
}
