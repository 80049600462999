import Typography from "components/elements/Typography";
import classes from "./classes.module.scss";
import classNames from "classnames";
import { useCallback, useContext, useEffect, useState } from "react";
import { FormContext } from "../FormContext";

type InputType = "text" | "password" | "textarea" | "date";

type IProps = {
	name: string;
	label?: string;
	type?: InputType;
	additionalInfo?: string;
	required?: boolean;
	placeholder?: string;
	onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
	defaultValue?: string | null;
	className?: string;
	inputClassName?: string;
	max?: any;
	disabled?: boolean;
};

export default function Input(props: IProps) {
	const [value, setValue] = useState(props.defaultValue || "");
	const type = props.type || "text";
	const context = useContext(FormContext);
	let errors = context?.getMessagesErrors(props.name);
	const hasErrors = errors?.length > 0;

	const onWheel = useCallback((event: React.WheelEvent<HTMLInputElement>) => {
		(event.target as HTMLInputElement).blur();
	}, []);

	// No useCallBack here because the only dependency is props.onChange
	const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value;
		let valueToSet = inputValue;

		setValue(valueToSet);
		event.target.value = valueToSet;
		props.onChange && props.onChange(event);
	};

	useEffect(() => {
		setValue(props.defaultValue || "");
	}, [props.defaultValue]);

	return (
		<div className={classNames(classes["root"], props.className)}>
			{props.label && (
				<label htmlFor={props.name} className={classes["label"]}>
					<Typography type="span" weight="regular" size="medium">
						{props.label}
						{props.required && "*"}
					</Typography>
				</label>
			)}
			<input
				id={props.name}
				onWheel={onWheel}
				placeholder={props.placeholder}
				name={props.name}
				type={type}
				required={props.required}
				value={value}
				onChange={handleInputChange}
				className={classNames(props.inputClassName, classes["input"])}
				max={props.max}
				disabled={props.disabled}
			/>
			{props.additionalInfo && (
				<Typography type="span" weight="regular" size="small" fontFamily="secondary" className={classes["additional-info"]}>
					{props.additionalInfo}
				</Typography>
			)}
			{hasErrors && (
				<div className={classes["errors-container"]}>
					{errors.map((message, i) => (
						<Typography type="p" size="small" weight="medium" key={i} color="error">
							{message}
						</Typography>
					))}
				</div>
			)}
		</div>
	);
}
